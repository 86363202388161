import React, { useState, useEffect } from "react";
import { Box, Flex, Header, Checkbox, Button } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";


export default function Flashcard({ driverList }) {
  const urlcpf = window.location.href.split('/').pop() ?? ''
  const [driver, setDriver] = useState()
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(true)

  const header = driver ? driver.name.toLocaleUpperCase() : 'Carregando'


  useEffect(() => {
    if (!driver) {
      const d = driverList.find(el => el.cpf === urlcpf)
      if (d) {
        setDriver(d)
        setChecked(d.flashcard)
        setLoading(false)

        console.log('useEffect')
        console.dir(d)
      }
    }
  }, [urlcpf, driverList, driver])

  const changed = driver && driver.flashcard !== checked

  return <Flex column={true} style={{ minHeight: '100vh'}}>
    <Header content={header} align="center"/>
    <Checkbox
      checked={checked}
      disabled={loading}
      label="CARTÃO FLASH"
      style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto'}}
      toggle
      onChange={(ev, data) => {
        setChecked(data.checked)
      }}
    />
    <Button
      style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '2em'}}
      content="Salvar"
      primary
      disabled={!driver || !changed || loading}
      onClick={() => {
        const url = `${process.env.REACT_APP_API_URL}/driver/card`
        const cpf = driver?.cpf
        
        if (cpf) {
          console.log(`cpf=${cpf}`)
          setLoading(true)
          const headers = new Headers();
          headers.append("Content-Type", "application/json");
          fetch(url, {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow',
            headers,
            body: JSON.stringify({
              cpf,
              flashcard: checked
            })
          })
            .then(() => {
              microsoftTeams.tasks.submitTask({
                cpf,
                flashcard: checked
              })
            })
            .catch(err => {
              console.log('err')
              // pra gente testar se da update
              microsoftTeams.tasks.submitTask()
            })
            .finally(() => {
              setLoading(false)
            })
        }
      }}
    />
  </Flex>
}