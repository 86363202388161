import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Flex, Divider, Box, Text, Button, FormDropdown, Checkbox } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import stringSimilarity from 'string-similarity'

import { RacPipe } from '../components/Rac'

const RacEdit = ({ driverList, groupList }) => {
  const { racid } = useParams()
  const [rac, setRac] = useState({})
  const [oldDriver, setOldDriver] = useState({})
  const [groupNameSelected, setGroupNameSelected] = useState('')
  const [driverSelected, setDriverSelected] = useState('')
  const [inputNameNotInDriverList, setInputNameNotInDriverList]  = useState(false)
  const [updating, setUpdating] = useState(false)

  const groupNamesOrdered = groupList?.map(el => el.group).sort() || []
  const [driverNamesOrdered, setDriverNamesOrdered] = useState([])
  const formatedeDate = new Date(rac?.data_servico).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo', weekday: 'long', year: '2-digit', month: 'numeric', day: 'numeric' })

  const fetchedDriver = !!oldDriver.cpf
  const changedDriver = !!driverSelected && driverSelected !== oldDriver.name

  useEffect(() => {
    const racidNumber = Number(racid)
    if (racidNumber) {
      const headers = new Headers()
      headers.append("Content-Type", "application/json")
      fetch(`${process.env.REACT_APP_API_URL}/rac/${racidNumber}`, {
        method: 'GET',
        headers,
        credentials: 'include',
        redirect: 'follow'
      })
        .then(response => response.json())
        .then(rac => {
          setRac(rac)
          let driver
          if (rac?.driver) {
            driver = driverList.find(el => el.cpf === rac.driver)
          } else if (rac?.cpf) {
            driver = driverList.find(el => el.cpf === rac.cpf)
          } else if (rac?.nome_motorista && driverList.length > 0) {
            const driverNameList = driverList.map(el => el.name)
            const lowName = rac?.nome_motorista.toLocaleLowerCase()
            const matches = stringSimilarity.findBestMatch(lowName, driverNameList)
            driver = driverList[matches.bestMatchIndex]
          }
          if (driver?.group &&  groupList.find(el => el.group === driver.group)){
            setGroupNameSelected(driver.group)
            
            const driversname = driverList
              .filter(el => el.group === driver.group)
              .map(el => el.name)
              .sort()
            setDriverNamesOrdered(driversname)
            
            setDriverSelected(driver.name)

            setOldDriver(driver)
          }
        })
    }


  }, [racid, setRac, driverList, groupList, setGroupNameSelected])

  return <Flex column={true} style={{ minHeight: '100vh'}}>
    <Flex space="between" vAlign="center">
      <Box>
        <Text style={{ display: 'block'}} size="largest" weight="bold" content={rac?.pipefy} />
        <Text style={{ display: 'block'}} size="largest" weight="bold" content={formatedeDate} />
      </Box>
      <Text size="largest" weight="bold" align="center" content={rac?.nome_motorista} />
    </Flex>
    <Divider />

    <Box style={{ marginLeft: 'auto', marginRight: 'auto', minWidth: '24rem'}}>
      <RacPipe rac={rac} />
      <Divider />
      <FormDropdown
        placeholder="Grupo"
        fluid={true}
        disabled={updating}
        items={groupNamesOrdered}
        value={groupNameSelected}
        onChange={(ev, data) => {
          if (data.value) {
            const groupname = data.value
            setGroupNameSelected(groupname)
            const driversname = driverList
              .filter(el => el.group === groupname)
              .map(el => el.name)
              .sort()

          setDriverNamesOrdered(driversname)
          setDriverSelected('')
          }
        }}
      />
      <FormDropdown
        placeholder="Motorista"
        fluid={true}
        disabled={updating}
        search={true}
        items={driverNamesOrdered}
        value={driverSelected}
        searchQuery={driverSelected}
        onSearchQueryChange={(ev, data) => {
          setDriverSelected(data.searchQuery)
          if (data.searchQuery === data.value) {
            setInputNameNotInDriverList(false)
          } else {
            setInputNameNotInDriverList(true)
          }
        }}
        onChange={(ev, data) => {
          setDriverSelected(data.searchQuery)
        }}
      />
      <Divider />
      <Box align="center">
        <Button
          style={{ marginTop: '3rem' }}
          primary
          content="SALVAR"
          onClick={() => {
            setUpdating(true)

            const newDriver = driverList.find(el => el.group === groupNameSelected && el.name === driverSelected)
            const url = `${process.env.REACT_APP_API_URL}/rac/${rac.id}/setdriver/${newDriver.cpf}`

            fetch(url, {
              method: 'GET',
              credentials: 'include',
              redirect: 'follow'
            })
            .then(() => {
              microsoftTeams.tasks.submitTask(newDriver)
            })
            .catch(() => {
              setUpdating(false)
            })

          }}
          disabled={!fetchedDriver || !changedDriver || updating || inputNameNotInDriverList}
        />
      </Box>
    </Box>

    <Text style={{ marginTop: 'auto'}} size="large" content="FlashPay" align="center" />
  </Flex>
}

export default RacEdit;
