import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Text } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";


const PipeRaw = () => {
  const { rawgenesys } = useParams()
  const [html, setHtml] = useState()

  useEffect(() => {
    if (rawgenesys) {
      fetch(`${process.env.REACT_APP_API_URL}/genesys/html/${rawgenesys}`, {
        method: 'GET',
        credentials: 'include',
        redirect: 'follow'
      })
        .then(response => response.text())
        .then(el => {
          setHtml(el)
        })
    }
  }, [rawgenesys, setHtml])

  return  !html ?
    <Text as="p" align="center" content="loading"/> :
    <Box dangerouslySetInnerHTML={{__html: html}}></Box>
}

export default PipeRaw;
