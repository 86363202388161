import React, { useState } from "react";
import { Flex, Header, Text } from "@fluentui/react-northstar";
import { Loader, Form, FormDropdown, FormInput, FormButton } from "@fluentui/react-northstar";

const GroupConf = ({ apilogin, groupList, setGroupList }) => {
  const [group, setGroup] = useState({})
  const [selected, setSelected] = useState(false)
  const [inname, setInname] = useState('')
  const [inmeal, setInmeal] = useState(0)
  const [ingrocery, setIngrocery] = useState(0)
  const [inmobility, setInmobility] = useState(0)
  const [fetching, setFetching] = useState(false)

  const notEdited = () => group.meal === inmeal &&
    group.grocery === ingrocery &&
    group.mobility === inmobility

  return <Flex column={true} style={{ minHeight: '100vh'}}>
  <Header content="Configuração de valores para os grupos" align="center"/>
  <Flex hAlign="center">
    {
    apilogin.notYet ?
      <Loader size="largest" label="Atualizando Dados" /> :
      <Form style={{ width: '24rem' }}>
        <FormDropdown placeholder="Grupo" items={groupList.map(el => el.group).sort()} fluid={true} disabled={fetching} value={inname} onChange={(ev, data) => {
          const name = data.value
          const groupSelected = groupList.find(el => el.group === name)
          setGroup(groupSelected)
          setSelected(true)
          setInname(groupSelected.group)
          setInmeal(groupSelected.meal)
          setIngrocery(groupSelected.grocery)
          setInmobility(groupSelected.mobility)
        }}/>
        <FormInput type="number" label="Refeição" name="Refeição" id="FormInputRefeicao" min="0" step="0.01" fluid={true} value={inmeal / 100} disabled={fetching} onChange={(ev, data) => {
          setInmeal(Math.round(Number.parseFloat(data.value) * 100))
        }}/>
        <FormInput type="number" label="Alimentação" name="Alimentacao" id="FormInputAlimentacao" min="0" step="0.01" fluid={true} value={ingrocery / 100} disabled={fetching} onChange={(ev, data) => {
          setIngrocery(Math.round(Number.parseFloat(data.value) * 100))
        }}/>
        <FormInput type="number" label="Mobilidade" name="Mobilidade" id="FormInputMobilidade"min="0" step="0.01" fluid={true} value={inmobility / 100} disabled={fetching} onChange={(ev, data) => {
          setInmobility(Math.round(Number.parseFloat(data.value) * 100))
        }}/>
        <FormButton content={fetching ? 'Salvando' : 'Salvar'} fluid={true} disabled={fetching || !selected || notEdited()} onClick={() => {
          setFetching(true)
          const actualGroupTip = {
            group: inname,
            meal: inmeal,
            grocery: ingrocery,
            mobility: inmobility
          }
          const myHeaders = new Headers()
          myHeaders.append("Content-Type", "application/json")

          fetch(`${process.env.REACT_APP_API_URL}/settip/${actualGroupTip.group}`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(actualGroupTip),
            credentials: 'include',
            redirect: 'follow'
          })
          .then(() => {
            const listFiltered = groupList.filter(el => el.group !== actualGroupTip.group)
            setGroupList([actualGroupTip, ...listFiltered])
          })
        }}/>
      </Form>
    }
  </Flex>
  <Text style={{ marginTop: 'auto'}} size="large" content="FlashPay" align="center" />
</Flex>
}

export default GroupConf;
