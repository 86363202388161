import React, { useEffect, useState } from "react"
import { Provider, teamsTheme } from "@fluentui/react-northstar"
import { useTeams } from "msteams-react-base-component";
import * as microsoftTeams from "@microsoft/teams-js";
import { HashRouter as Router, Route } from "react-router-dom"

import Privacy from "./pages/Privacy";
import TermsOfUse from "./pages/TermsOfUse";
import Rac from "./pages/Rac";
import DriverPay from "./pages/DriverPay";
import GroupConf from "./pages/GroupConf";
import Home from "./pages/Home";
import TabConfig from "./pages/TabConfig";
import RacEdit from "./pages/RacEdit";
import PipeRaw from "./pages/PipeRaw";
import Flashcard from "./pages/Flashcard";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme } = useTeams({})[0];
  const [driverList, setDriverList] = useState([])
  const [groupList, setGroupList] = useState([])
  const [tipList, setTipList] = useState([])
  const [teamsContext, setTeamsContext] = useState({})
  const [teamsToken, setTeamsToken] = useState('')
  const [apilogin, setApilogin] = useState({ notYet: true })
  
  useEffect(() => {
    /** LOGIN BY TEAMS **/
    // microsoftTeams.initialize()

    // const contextP = new Promise((res, rej) => {
    //   microsoftTeams.getContext(context => {
    //     setTeamsContext(context)
    //     res(context)
    //   })
    // })

    // const tokenP = new Promise((res, rej) => {
    //   microsoftTeams.authentication.getAuthToken({
    //     successCallback: token => {
    //       setTeamsToken(token)
    //       res(token)
    //     },
    //     failureCallback: rej
    //   })
    // })

    // Promise.all([contextP, tokenP])
    //   .then(([context, token]) => {
    //     const headers = new Headers()
    //     headers.append("Content-Type", "application/json")
    //     const body = JSON.stringify({ context, token })

    //     return fetch(`${process.env.REACT_APP_API_URL}/login`, {
    //       method: 'POST',
    //       headers,
    //       body,
    //       credentials: 'include',
    //       redirect: 'follow'
    //     })
    //   })
    //   .then(response => response.json())
    //   .then(loginResponse => {
    //     setApilogin({
    //       cnpj: loginResponse.cnpj,
    //       username: loginResponse.username
    //     })
    //     setDriverList(loginResponse.drivers)
    //     setGroupList(loginResponse.groups)
    //     setTipList(loginResponse.tips)
    //   })
    //   .catch(() => {
    //     setDriverList([])
    //     setGroupList([])
    //     setTipList([])
    //   })
    /** LOGIN BY TEAMS **/

    /** LOGIN HARDCODED **/
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const url = `${process.env.REACT_APP_API_URL}/loginfake`
    const config = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        username: process.env.REACT_APP_API_USERNAME,
        secret: process.env.REACT_APP_API_SECRET
      }),
      credentials: 'include',
      redirect: 'follow'
    }
    console.dir({
      url,
      config
    })
    fetch(url, config)
      .then(response => response.json())
      .then(loginResponse => {
        setApilogin({
          cnpj: loginResponse.cnpj,
          username: loginResponse.username
        })
        setDriverList(loginResponse.drivers)
        setGroupList(loginResponse.groups)
        setTipList(loginResponse.tips)
      })
      .catch(() => {
        setDriverList([])
        setGroupList([])
        setTipList([])
      })
    /** LOGIN HARDCODED **/
  }, [])

  return (
    <Provider theme={theme || teamsTheme} >
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/termsofuse" component={TermsOfUse} />
        <Route exact path="/rac" component={() => <Rac driverList={driverList} setDriverList={setDriverList} groupList={groupList} apilogin={apilogin} />} />
        <Route exact path="/driverpay" component={() => <DriverPay driverList={driverList} setDriverList={setDriverList} groupList={groupList} apilogin={apilogin} tipList={tipList} setTipList={setTipList}/>} />
        <Route exact path="/groupconf" component={() => <GroupConf groupList={groupList} setGroupList={setGroupList} apilogin={apilogin}/>} />
        <Route exact path="/configtab" component={TabConfig} />
        <Route exact path="/racedit/:racid" component={() => <RacEdit driverList={driverList} groupList={groupList} />} />
        <Route exact path="/flashcard/:cpf" component={() => <Flashcard  driverList={driverList} />} />
        <Route exact path="/rawgenesys/:rawgenesys" component={PipeRaw} />
      </Router>
    </Provider>
  );
}
