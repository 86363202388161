import React, { useState } from "react";
import { Box, Text, Flex, Button, ArrowLeftIcon, Divider, Datepicker, Form, FormInput, FormRadioGroup, FormTextArea, DayOfWeek, Input } from "@fluentui/react-northstar";

const today = new Date()

const PayTip = ({ tipSelected, groupSelected, driverSelected, setConfirmpay, onResponse }) => {
  const [creditDate, setCreditDate] = useState(tipSelected?.payday || today)
  const [meal, setMeal] = useState(groupSelected?.meal)
  const [grocery, setGrocery] = useState(groupSelected?.grocery)
  const [mobility, setMobility] = useState(groupSelected?.mobility)
  const [awards, setAwards] = useState(0)
  const [flexible, setFlexible] = useState(0)
  const [obs, setObs] = useState(tipSelected?.obs)
  const [type, setType] = useState(tipSelected?.type || 'rac')
  const [loading, setLoading] = useState(false)

  const name = driverSelected?.name?.toUpperCase()
  const cpfA = driverSelected?.cpf?.slice(0, 3)
  const cpfB = driverSelected?.cpf?.slice(3, 6)
  const cpfC = driverSelected?.cpf?.slice(6, 9)
  const cpfD = driverSelected?.cpf?.slice(-2)
  const cpf = `${cpfA}.${cpfB}.${cpfC}-${cpfD}`
  const group = driverSelected.group

  const email = driverSelected.email ? <a href={`mailto:${driverSelected.email}`} target="_blank" rel="noreferrer">{driverSelected.email}</a> : null
  const zap = driverSelected.phone ? <a href={`https://wa.me/55${driverSelected.phone}`} target="_blank" rel="noreferrer">{driverSelected.phone}</a> : null
  const contato = <span>Email: {email}{email && zap ? ' ' : null}Whatsapp: {zap}</span>

  return <Box style={{ width: '40rem', marginLeft: 'auto', marginRight: 'auto' }}>
    <Flex hAlign="start">
      <Button icon={<ArrowLeftIcon />} text tinted content="Voltar" onClick={() => {
        setConfirmpay(false)
      }} />
    </Flex>
    <Divider content="Colaborador" style={{ marginTop: '1rem', marginBottom: '0rem' }} />
    <Flex gap="gap.smaller">
      <Input label="Nome" fluid disabled value={name} />
      <Input label="CPF" fluid style={{ maxWidth: '125px' }} disabled value={cpf} />
      <Input label="Grupo" fluid style={{ maxWidth: '185px' }} disabled align="left" size="medium" value={group.charAt(0).toUpperCase() + group.slice(1)} />
    </Flex>
    <Flex gap="gap.smaller" hAlign="end">
      <Text as="p" align="center" content={<span>WhatsApp 📞 {zap}</span>} />
      <Text as="p" align="center" content={<span>Email 📧 {email}</span>} />
    </Flex>
    <Divider content="Data para crédito" style={{ marginTop: '0rem', marginBottom: '1rem' }} />
    <Datepicker
      minDate={today}
      defaultSelectedDate={tipSelected?.payday || today}
      today={creditDate}
      style={{ textAlign: 'center' }}
      formatMonthDayYear={d => d.toLocaleDateString('pt-BR', { weekday: 'long', year: '2-digit', month: 'numeric', day: 'numeric' })}
      firstDayOfWeek={DayOfWeek.Sunday}
      onDateChange={(ev, data) => {
        setCreditDate(data.value)
      }}
    />
    <Divider content="Valores dos Benefícios" style={{ marginTop: '1rem', marginBottom: '1rem' }} />
    <Form style={{ width: '40rem', marginLeft: 'auto', marginRight: 'auto' }}>
      <Flex space="between" hAlign="center">
        <FormInput type="number" label="Refeição 🍽️" name="Refeição" id="FormPayRefeicao" min="0" step="0.01" value={meal / 100} disabled={loading} onChange={(event, data) => {
          setMeal(Number.parseFloat(data.value) * 100)
        }} />
        <FormInput type="number" label="Alimentação 🛒" name="Alimentacao" id="FormPayAlimentacao" min="0" step="0.01" value={grocery / 100} disabled={loading} onChange={(event, data) => {
          setGrocery(Number.parseFloat(data.value) * 100)
        }} />
        <FormInput type="number" label="Mobilidade 🛴" name="Mobilidade" id="FormPayMobilidade" min="0" step="0.01" value={mobility / 100} disabled={loading} onChange={(event, data) => {
          setMobility(Number.parseFloat(data.value) * 100)
        }} />
      </Flex>
      <Flex space="between" hAlign="center" style={{ marginTop: '0 rem', marginBottom: "1 rem" }}>
        <FormInput type="number" label="Premiação 🎁" name="Premiação" id="FormPayPremiacao" min="0" step="0.01" fluid={true} value={awards / 100} disabled={loading} onChange={(event, data) => {
          setAwards(Number.parseFloat(data.value) * 100)
        }} />
        <FormInput type="number" label="Flexivel 💪" name="Flexivel" id="FormPayFlexivel" min="0" step="0.01" fluid={true} value={flexible / 100} disabled={loading} onChange={(event, data) => {
          setFlexible(Number.parseFloat(data.value) * 100)
        }} />
        <FormRadioGroup style={{ marginLeft: '2.322rem', marginRight: '2.322rem' }} label="Tipo" vertical defaultCheckedValue="rac" value={type} items={[
          {
            name: 'rac',
            key: 'RAC',
            label: 'RAC',
            value: 'rac',
          },
          {
            name: 'desativacao',
            key: 'desativacao',
            label: 'Desativação',
            value: 'desativacao',
          }
        ]} onCheckedValueChange={(ev, data) => {
          setType(data.value)
        }} />
      </Flex>
      <Flex hAlign="center">
        <Flex.Item size="size.large">
          <FormTextArea label="Observações" name="obs" rows={8} id="FormPayObs" fluid value={obs} disabled={loading} onChange={(event, data) => {
            setObs(data.value)
          }} />
        </Flex.Item>
      </Flex>
    </Form>

    <Button
      content={`${tipSelected?.invoice ? 'Clonar' : 'Faturar'} Benefício`}
      primary
      fluid
      style={{ height: '2rem', marginLeft: 'auto', marginRight: 'auto', marginTop: '2rem', marginBottom: '2rem' }}
      disabled={loading}
      onClick={() => {
        setLoading(true)
        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        fetch(`${process.env.REACT_APP_API_URL}/paytip`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            cpf: driverSelected.cpf,
            meal,
            grocery,
            mobility,
            awards,
            flexible,
            creditDate,
            type,
            obs
          }),
          credentials: 'include',
          redirect: 'follow'
        })
          .then(response => response.json())
          .then(newTip => {
            setLoading(false)
            onResponse(newTip)
          })
      }}
    />
  </Box>
}

export default PayTip