import React from "react";
import { Text, Box } from "@fluentui/react-northstar";

import toBR from '../components/toBR'

const RacPipe = ({ rac }) => <Box style={{ width: '100%' }}>
  <Text as="p" style={{ textAlign: 'center', marginTop: '2px', marginBottom: '2px' }} size="larger" content={`${rac.regiao} - ${rac.sigla_agencia}`} />
  <Text as="p" style={{ textAlign: 'center', marginTop: '2px', marginBottom: '2px' }} size="large" content={rac.tipo_tarifa} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px' }} content={`CPF: ${rac.cpf_motorista}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px' }} content={`ENTRADA: ${rac.hora_entrada}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px' }} content={`SAIDA: ${rac.hora_saida}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px' }} content={`COD13: ${rac.codigo_13 ? 'SIM' : 'NÃO'}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px' }} content={`COD14: ${rac.mais_flash ? toBR(String(rac.mais_flash)) : ''}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px' }} content={`COD15: ${rac.codigo_15}`} />
</Box>

const RacGroup = ({ group }) => <Box style={{ width: '100%' }}>
  <Text as="p" style={{ textAlign: 'center', marginTop: '2px', marginBottom: '2px' }} size="larger" content="GRUPO" />
  <Text as="p" style={{ textAlign: 'center', marginTop: '2px', marginBottom: '2px'  }} size="large" content={group ? group.group : '???'} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px'  }} content={`Refeição: ${group ? toBR(group.meal / 100) : ''}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px'  }} content={`Alimentação: ${group ? toBR(group.grocery / 100) : ''}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px'  }} content={`Transporte: ${group ? toBR(group.mobility / 100) : ''}`} />
</Box>

const RacComp = ({ meal, grocery, mobility, awards, flexible }) => <Box style={{ width: '100%' }}>
  <Text as="p" style={{ textAlign: 'center', marginTop: '2px', marginBottom: '2px' }} size="larger" content="COMPUTADO" />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px'  }} content={`Refeição: ${meal ? toBR(meal / 100) : ''}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px'  }} content={`Alimentação: ${grocery ? toBR(grocery / 100) : ''}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px'  }} content={`Transporte: ${mobility ? toBR(mobility / 100) : ''}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px'  }} content={`Premiação: ${awards ? toBR(awards / 100) : ''}`} />
  <Text as="p" style={{ marginTop: '2px', marginBottom: '2px'  }} content={`Flexivel: ${flexible ? toBR(flexible / 100) : ''}`} />
</Box>

export {
  RacPipe,
  RacGroup,
  RacComp
}