import React from "react";
import { List, ButtonGroup, Box, Text } from "@fluentui/react-northstar";
import { EditIcon, PopupIcon, DownloadIcon } from '@fluentui/react-icons-northstar'

import toBR from '../components/toBR'

const listStyle = {
  maxWidth: '72rem',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto'
}

const sorter = (arr = []) => arr
  .map(el => ({
    ...el,
    invoiceCreatedAt: new Date(el.invoiceCreatedAt),
    payday: new Date(el.payday)
  }))
  .sort((a, b) => b.invoiceCreatedAt - a.invoiceCreatedAt)

const TipList = ({ items, onEditTip, onPopTip }) => <List style={listStyle} items={sorter(items).map(tip => {
  const invoice = tip.invoice
  const driver = tip.driver
  const cpf = tip.cpf
  const group = tip.group?.toLocaleUpperCase()
  const payday = tip.payday
  const values = tip.values
  const uuid = tip.uuid
  const hasPdf = tip.hasPdf
  const type = tip.type
  const obs = tip.obs

  const key = `${invoice}-${hasPdf}`
  const header = `${invoice}) ${group} ${type} — ${driver} :: ${payday.toLocaleDateString('pt-BR', { weekday: 'long', year: '2-digit', month: 'numeric', day: 'numeric' })}`

  const meal = `Refeição 🍽️ ${toBR(values?.meal || 0 / 100)} 🍽️`
  const grocery = `Alimentação 🛒 ${toBR(values?.grocery || 0 / 100)} 🛒`
  const mobility = `Transporte 🛴 ${toBR(values?.mobility || 0 / 100)} 🛴`
  const awards = `Premiação 🎁 ${toBR(values?.awards || 0 / 100)} 🎁`
  const flexible = `Flexivel 💳 ${toBR(values?.flexible || 0 / 100)} 💳`
  const content = <Box>
    <Text as="p" content={meal} />
    <Text as="p" content={grocery} />
    <Text as="p" content={mobility} />
    <Text as="p" content={awards} />
    <Text as="p" content={flexible} />
    <Text as="p" content={obs} />
  </Box>
  // <Button disabled={!hasPdf} as="a" target="_blank" href={`${process.env.REACT_APP_API_URL}/${uuid}.pdf`} icon={} iconOnly text title="Download" />
  const endMedia = <ButtonGroup
    buttons={[
      {
        icon: <PopupIcon size="larger"/>,
        iconOnly: true,
        text: true,
        title: 'Replicar',
        key: 'Replicar',
        onClick: () => onPopTip({
          invoice,
          driver: tip.driver,
          cpf: tip.cpf,
          group: tip.group,
          payday: tip.payday,
          meal: tip.values?.meal  || 0,
          grocery: tip.values?.grocery || 0,
          mobility: tip.values?.mobility || 0,
          awards: tip.values?.awards || 0,
          flexible: tip.values?.flexible || 0,
          invoiceCreatedAt: tip.invoiceCreatedAt,
          type: tip.type,
          obs: tip.obs
        })
      },
      {
        icon: <EditIcon size="larger"/>,
        iconOnly: true,
        text: true,
        title: 'Observações',
        key: 'Observações',
        onClick: () => onEditTip({ invoice })
      },
      {
        icon: <DownloadIcon size="larger"/>,
        iconOnly: true,
        text: true,
        disabled: !hasPdf,
        as: 'a',
        target: "_blank",
        href: `${process.env.REACT_APP_API_URL}/${uuid}.pdf`,
        title: 'Baixar',
        key: 'Baixar',
      }
    ]}
  />
  return {
    key,
    header,
    content,
    endMedia
  }
})}/>


export default TipList;