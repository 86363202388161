import React, { useState, useEffect } from "react";
import { Flex, Header, Text, Divider, Box, Loader } from "@fluentui/react-northstar";
import TipList from '../components/TipList'
import DriverPicker from '../components/DriverPicker'
import PayTip from '../components/PayTip'


const DriverPay = ({ apilogin, driverList, setDriverList, groupList, tipList, setTipList }) => {
  const [groupSelected, setGroupSelected] = useState()
  const [driverSelected, setDriverSelected] = useState()
  const [tipSelected, setTipSelected] = useState()
  const [confirmpay, setConfirmpay] = useState(false)

  useEffect(() => {
    if (!confirmpay) {
      const intervalId = setInterval(async () => {
        const processing = tipList?.filter(el =>
          (!el.hasPdf) && // se não tem pdf ainda E
          (Date.now() - new Date(el.invoiceCreatedAt)) < 1000 * 60 * 5 // não demorou 5 minutos
        ) || []
  
        if (processing.length !== 0) {
          const headers = new Headers()
          headers.append("Content-Type", "application/json")
          const tipstoday = await fetch(`${process.env.REACT_APP_API_URL}/tipstoday`, {
            method: 'GET',
            headers,
            credentials: 'include',
            redirect: 'follow'
          })
            .then(response => response.json())
  
          const processingToday = tipstoday.filter(el =>
            (!el.hasPdf) && // se não tem pdf ainda E
            (Date.now() - new Date(el.invoiceCreatedAt)) < 1000 * 60 * 5 // não demorou 5 minutos
          )
  
          if (tipList.length !== tipstoday.length || processing.length !== processingToday.length) {
            setTipList(tipstoday)
          } else {
          }
        }
  
      }, 5_000)
  
      return () => clearInterval(intervalId)
    }
  }, [tipList, setTipList, confirmpay])

  return <Flex column={true} style={{ minHeight: '100vh'}}>
  <Header content="Pagamento de benefícios para motoristas" align="center"/>
  {
    apilogin.notYet ?
      <Loader size="largest" label="Atualizando Dados" /> :
      confirmpay ?
        <PayTip groupSelected={groupSelected} driverSelected={driverSelected} tipSelected={tipSelected} setConfirmpay={setConfirmpay} onResponse={newTip => {
          const newTipList = [newTip, ...tipList]
          setConfirmpay(false)
          setTipList(newTipList)
        }}/> :
        <Box>
          <DriverPicker groupList={groupList} setDriverList={setDriverList} driverList={driverList} onMainClick={picked => {
            setGroupSelected(picked.groupSelected)
            setDriverSelected(picked.driverSelected)
            setTipSelected()
            setConfirmpay(true)
          }}/>
          <Divider content="Histórico" style={{ marginTop: '4rem' }}/>
          <TipList items={tipList} onPopTip={driverGroupTip => {
            const virtualGroup = groupList.find(el => el.group === driverGroupTip.group)
            const virtualDriver = driverList.find(el => el.cpf === driverGroupTip.cpf)
            const nextDay = new Date(driverGroupTip.payday)
            nextDay.setDate(nextDay.getDate() + 1)
            const virtualTip = {
              invoice: driverGroupTip.invoice,
              payday: nextDay,
              type: driverGroupTip.type,
              obs: driverGroupTip.obs
            }
            setGroupSelected(virtualGroup)
            setDriverSelected(virtualDriver)
            setTipSelected(virtualTip)
            setConfirmpay(true)
          }}/>
        </Box>
  }
  <Text style={{ marginTop: 'auto'}} size="large" content="FlashPay" align="center" />
  </Flex>
}

export default DriverPay;
