import React from "react";
import { Box, Flex, Header, Text, Loader } from "@fluentui/react-northstar";

import DownloadExcelButton from '../components/DownloadExcelButton'

const Rac = ({ driverList, setDriverList, groupList, apilogin }) => <Flex column={true} style={{ minHeight: '100vh'}}>
  <Header content="RAC genesys" align="center" description="Baixe a planilha para pagar na flash"/>
  {
    apilogin.notYet ?
    <Loader size="largest" label="Atualizando Dados" /> :
    <Box>
      <DownloadExcelButton groupList={groupList} driverList={driverList} />
    </Box>
  }
  <Text style={{ marginTop: 'auto'}} size="large" content="FlashPay" align="center" />
</Flex>

export default Rac