import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Box, Flex, FlexItem, Header, Text, Button } from "@fluentui/react-northstar";

const tabspath = {
  groupconf: {
    path: 'groupconf',
    suggestedDisplayName: 'Flash Grupos',
    entityId: "FlashGroups"
  },
  driverpay: {
    path: 'driverpay',
    suggestedDisplayName: 'Flash Pay',
    entityId: "FlashPay"
  },
  rac: {
    path: 'rac',
    suggestedDisplayName: 'RAC',
    entityId: "RAC"
  }
}

const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
const setSaveTab = path => {
  microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
    
    microsoftTeams.settings.setSettings({
      suggestedDisplayName: tabspath[path].suggestedDisplayName,
      entityId: tabspath[path].entityId,
      contentUrl: `${baseUrl}/#/${path}`,
      websiteUrl: baseUrl,
    });
    saveEvent.notifySuccess();
  });
  microsoftTeams.settings.setValidityState(true);
}

const TabConfig = () => {
  useEffect(() => {
    microsoftTeams.initialize();
  }, [])
  const [tabpicked, setTabpicked] = useState(false)

  return <Flex column={true} style={{ height: '100%'}}>
    <Header content="Adicione Flash Benefícios a sua equipe" />
    <Text size="large" content="O aplicativo consulta a base de motoristas no sistema da flash benefícios, configura valores para os grupos e paga benefícios" />
    <Box style={{ marginTop: '3rem' }}>
      <Text weight="bold" content="Escolha a TAB para ser adicionada" align="center"/>
      <Flex gap="gap.large"  hAlign="center" style={{ marginTop: '1rem' }}>
        <Button content="Flash Grupos" disabled={tabpicked} onClick={() => {
          setTabpicked(true)
          setSaveTab(tabspath.groupconf.path)
        }}/>
        <Button content="Flash Pay" disabled={tabpicked} onClick={() => {
          setTabpicked(true)
          setSaveTab(tabspath.driverpay.path)
        }}/>
        <Button content="RAC" disabled={tabpicked} onClick={() => {
          setTabpicked(true)
          setSaveTab(tabspath.rac.path)
        }}/>
      </Flex>
    </Box>
    <FlexItem push>
      <Text size="large" content="FlashPay" align="center" />
    </FlexItem>
  </Flex>
}

export default TabConfig;
