import React, { useState } from "react"
import { Flex, Button, Datepicker, DayOfWeek, Dropdown, Box, Divider, Input, AddIcon } from "@fluentui/react-northstar"
import stringSimilarity from 'string-similarity'
import * as XLSX from 'xlsx'

const excelCols = [
  "CNPJ",
  "motorista pipefy",
  "NOME COMPLETO", // 19
  "semelhança",
  "CPF", // 20
  "IDENTIFICACAO ADICIONAL", // 21
  "E-MAIL", // 22
  "TELEFONE CELULAR", // 23
  "GRUPO DE TRABALHO (OPCIONAL)", // 24
  "FLEXIVEL (R$)", // 25
  "PREMIACAO NO CARTAO (R$)", // 26
  "ALIMENTACAO (R$) (Use em Refeição e Alimentação)", // 27
  "REFEICAO (R$) (Use em Refeição e Alimentação)", // 28
  "ALIMENTACAO (R$)", // 29
  "REFEICAO (R$)", // 30
  "REFEICAO E ALIMENTACAO (R$)", // 31
  "MOBILIDADE (R$)", // 32
  "PREMIACAO VIRTUAL (R$)", // 33
  "VALE TRANSPORTE (R$)", // 34
  "TAGS", // 35
]

const excelHeader = ['genesys', 'regiao', 'agencia', 'pipefy', 'solicitacao', 'responsavel', 'servico', 'entrada', 'saida', 'cod13', 'cod14', 'cod15', 'cpf', 'cartao', 'grocery', 'meal', 'mobility'].concat(excelCols)
const genExcelDownload = ({ racs, driverList, groupList, excellDate }, filenamequo = '') => {
  const totalBodyLines = racs.length
  const driverNameList = driverList.map(el => el.name)

  const excelBody = racs.map(el => {
    const col0 = el.genesysuser
    const col1 = el.regiao
    const col2 = el.sigla_agencia
    const col3 = el.pipefy
    const col4 = new Date(el.data_solicitacao).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo', year: '2-digit', month: 'numeric', day: 'numeric' })
    const col5 = el.responsavel_atendimento
    const col6 = new Date(el.data_servico).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo', year: '2-digit', month: 'numeric', day: 'numeric' })
    const col7 = el.hora_entrada
    const col8 = el.hora_saida
    const col9 = el.codigo_13 ? 'SIM' : 'NAO'
    const col10 = el.mais_flash // Cod14
    const col11 = el.codigo_15
    const col12 = el.nome_motorista
    const col13 = el.cpf_motorista
    let col14 = '' // driver.flashcard
    let col15 = '' // group.grocery
    let col16 = '' // group.meal
    let col17 = '' // group.mobility
    const col18 = '42537087000261'
    let col19 = ''
    let score = ''
    let col20 = ''
    let col21 = ''
    let col22 = ''
    let col23 = ''
    let col24 = ''
    let col25 = ''
    let col26 = ''
    let col27 = ''
    let col28 = ''
    let col29 = ''
    let col30 = ''
    let col31 = ''
    let col32 = ''
    let col33 = ''
    let col34 = ''
    let col35 = ''

    // todo
    // [ ] memorizar

    const lowName = el.nome_motorista.toLocaleLowerCase()
    if (el.driver || lowName) {
      let driver
      if (el.driver) {
        driver = driverList.find(d => d.cpf === el.driver)
      } else {
        const matches = stringSimilarity.findBestMatch(lowName, driverNameList)
        score = String(matches.bestMatch.rating).slice(0, 4)
        driver = driverList[matches.bestMatchIndex]
      }

      const group = groupList.find(el => el.group === driver.group)
      if (group) {
        col14 = driver.flashcard ? 'SIM' : 'NAO'
        col15 = group.grocery
        col16 = group.meal
        col17 = group.mobility

        col19 = driver.name
        col20 = driver.cpf
        col22 = driver.email
        col23 = driver.phone
        col24 = driver.group

        col27 = el.codigo_13 ? 0 : group.grocery
        col28 = el.codigo_13 ? 0 : group.meal
        col32 = el.codigo_13 ? 0 : group.mobility

        col32 = el.mais_flash > 0 ? Math.round(el.mais_flash * 100) : col32

        if (!driver.flashcard) {
          col33 = col27 + col28 + col32
          col27 = 0
          col28 = 0
          col32 = 0
          col33 = col33 > 0 ? col33 + 200 : 0
        }

        // reais 
        col27 = col27 / 100
        col28 = col28 / 100
        col32 = col32 / 100
        col33 = col33 / 100

      }
    }

    const colMotoristaPipefy = col12

    return [col0, col1, col2, col3, col4, col5, col6, col7, col8, col9, col10, col11, col13, col14, col15, col16, col17, col18, colMotoristaPipefy, col19, score, col20, col21, col22, col23, col24, col25, col26, col27, col28, col29, col30, col31, col32, col33, col34, col35]
  })

  const aoa = [
    excelHeader,
    ...excelBody
  ]
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(aoa)
  XLSX.utils.book_append_sheet(wb, ws, 'pipefys')
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer', bookSST: false })

  const file = new Blob([wbout], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  const url = window.URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = url

  const dayPrint = excellDate.toLocaleDateString('pt-BR', { year: '2-digit', month: 'numeric', day: 'numeric',  }).replaceAll('/', '-')
  link.setAttribute(
    'download',
    `flash_${totalBodyLines}_${filenamequo}_${dayPrint}.xlsx`,
  )
  
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}
const genExcelDownloadAcc = ({ racs, driverList, groupList, excellDate }, filenamequo = '') => {
  const driverNameList = driverList.map(el => el.name)

  const excelBody = racs.map(el => {
    const col0 = el.genesysuser
    const col1 = el.regiao
    const col2 = el.sigla_agencia
    const col3 = el.pipefy
    const col4 = new Date(el.data_solicitacao).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo', year: '2-digit', month: 'numeric', day: 'numeric' })
    const col5 = el.responsavel_atendimento
    const col6 = new Date(el.data_servico).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo', year: '2-digit', month: 'numeric', day: 'numeric' })
    const col7 = el.hora_entrada
    const col8 = el.hora_saida
    const col9 = el.codigo_13 ? 'SIM' : 'NAO'
    const col10 = el.mais_flash // Cod14
    const col11 = el.codigo_15
    const col12 = el.nome_motorista
    const col13 = el.cpf_motorista
    let col14 = '' // driver.flashcard
    let col15 = '' // group.grocery
    let col16 = '' // group.meal
    let col17 = '' // group.mobility
    const col18 = '42537087000261'
    let col19 = ''
    let score = ''
    let col20 = ''
    let col21 = ''
    let col22 = ''
    let col23 = ''
    let col24 = ''
    let col25 = ''
    let col26 = ''
    let col27 = ''
    let col28 = ''
    let col29 = ''
    let col30 = ''
    let col31 = ''
    let col32 = ''
    let col33 = ''
    let col34 = ''
    let col35 = ''

    // todo
    // [ ] memorizar

    const lowName = el.nome_motorista.toLocaleLowerCase()
    if (el.driver || lowName) {
      let driver
      if (el.driver) {
        driver = driverList.find(d => d.cpf === el.driver)
      } else {
        const matches = stringSimilarity.findBestMatch(lowName, driverNameList)
        score = String(matches.bestMatch.rating).slice(0, 4)
        driver = driverList[matches.bestMatchIndex]
      }

      const group = groupList.find(el => el.group === driver.group)
      if (group) {
        col14 = driver.flashcard ? 'SIM' : 'NAO'
        col15 = group.grocery
        col16 = group.meal
        col17 = group.mobility

        col19 = driver.name
        col20 = driver.cpf
        col22 = driver.email
        col23 = driver.phone
        col24 = driver.group

        col27 = el.codigo_13 ? 0 : group.grocery
        col28 = el.codigo_13 ? 0 : group.meal
        col32 = el.codigo_13 ? 0 : group.mobility

        col32 = el.mais_flash > 0 ? Math.round(el.mais_flash * 100) : col32

        if (!driver.flashcard) {
          col33 = col27 + col28 + col32
          col27 = 0
          col28 = 0
          col32 = 0
          col33 = col33 > 0 ? col33 + 200 : 0
        }

        // reais 
        col27 = col27 / 100
        col28 = col28 / 100
        col32 = col32 / 100
        col33 = col33 / 100

      }
    }

    const colMotoristaPipefy = col12

    return [col0, col1, col2, col3, col4, col5, col6, col7, col8, col9, col10, col11, col13, col14, col15, col16, col17, col18, colMotoristaPipefy, col19, score, col20, col21, col22, col23, col24, col25, col26, col27, col28, col29, col30, col31, col32, col33, col34, col35]
  })

  const excelBodyMap = new Map()
  excelBody.forEach(row => {
    const cpfpipefy = `${row[21]}-${row[3]}`
    if (excelBodyMap.has(cpfpipefy)) {
      const cpfRows = excelBodyMap.get(cpfpipefy)
      cpfRows.push(row)
    } else {
      excelBodyMap.set(cpfpipefy, [row])
    }
  })

  const excelBodyGroups = Array.from(excelBodyMap.values())
  // .filter(el => el.length > 1)

  const excelBodyCollect = excelBodyGroups.map(group => {
    const el0 = group[0][0]
    const el1 = group[0][1]
    const el2 = group[0][2]
    const el3 = group[0][3]
    const el4 = group[0][4]
    const el5 = group[0][5]
    const el6 = group.map(el => el[6]).join(',')
    const el7 = group[0][7]
    const el8 = group.slice(-1)[0][8]
    const el9 = group[0][9]
    const el10 = group[0][10]
    const el11 = group[0][11]
    const el12 = group[0][12]
    const el13 = group[0][13]
    const el14 = group[0][14]
    const el15 = group[0][15]
    const el16 = group[0][16]
    const el17 = group[0][17]
    const el18 = group[0][18]
    const el19 = group[0][19]
    const el20 = group[0][20]
    const el21 = group[0][21]
    const el22 = group[0][22]
    const el23 = group[0][23]
    const el24 = group[0][24]
    const el25 = group[0][25]
    const el26 = group[0][26]
    const el27 = group[0][27]
    const el28 = group.map(el => el[28]).reduce((a, b) => a + b, 0)
    const el29 = group.map(el => el[29]).reduce((a, b) => a + b, 0)
    const el30 = group[0][30]
    const el31 = group[0][31]
    const el32 = group[0][32]
    const el33 = group.map(el => el[33]).reduce((a, b) => a + b, 0)
    const el34 = group.map(el => el[34]).reduce((a, b) => a + b, 0)
    const el35 = group[0][35]
    const el36 = group[0][36]

    return [el0, el1, el2, el3, el4, el5, el6, el7, el8, el9, el10, el11, el12, el13, el14, el15, el16, el17, el18, el19, el20, el21, el22, el23, el24, el25, el26, el27, el28, el29, el30, el31, el32, el33, el34, el35, el36]
  })

  const totalBodyLines = excelBodyCollect.length

  const aoa = [
    excelHeader,
    ...excelBodyCollect
  ]
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(aoa)
  XLSX.utils.book_append_sheet(wb, ws, 'pipefys')
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer', bookSST: false })

  const file = new Blob([wbout], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  const url = window.URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = url

  const dayPrint = excellDate.toLocaleDateString('pt-BR', { year: '2-digit', month: 'numeric', day: 'numeric',  }).replaceAll('/', '-')
  link.setAttribute(
    'download',
    `flash_${totalBodyLines}_${filenamequo}_${dayPrint}.xlsx`,
  )
  
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

const today = new Date()

const usuarioGenesysItens = [
  'Gabriele Bueno',
  'Gabriella Cristina de Oliveira Xavier',
  'Juliane Gonçalves Ferreira de Lima',
]

const DownloadExcelButton = ({ groupList, driverList }) => {
  const [disabledExcell, setDisabledExcell] = useState(false)
  const [excellDate, setExcellDate] = useState(today)
  const [genuser, setGenuser] = useState(null)
  const [pipefyInput, setPipefyInput] = useState()
  const [pipefys, setPipefys] = useState([])


  return <>
    <Flex hAlign="center">
      <Datepicker
        formatMonthDayYear={d => d.toLocaleDateString('pt-BR', { weekday: 'long', year: '2-digit', month: 'numeric', day: 'numeric' })}
        firstDayOfWeek={DayOfWeek.Sunday}
        defaultSelectedDate={today}
        buttonOnly
        onDateChange={(ev, data) => {
          setExcellDate(data.value)
        }}
      />
      <Dropdown
        clearable
        items={usuarioGenesysItens}
        placeholder="Usuário Genesys"
        value={genuser}
        onChange={(ev, data) => {
        setGenuser(data.value)
      }}/>
      <Button
        disabled={disabledExcell}
        content="dia serviço"
        title="Baixar planilha"
        onClick={() => {
          setDisabledExcell(true)
          const day = excellDate.toString()
          const queryParam = genuser ? `?${new URLSearchParams({ day, genuser })}` : `?${new URLSearchParams({ day })}`
          const url = `${process.env.REACT_APP_API_URL}/rac/sheet${queryParam}`

          fetch(url, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
          })
          .then(response => response.json())
          .then(racs => {
            genExcelDownload({
              racs,
              driverList,
              groupList,
              excellDate
            }, 'serviço')
          })
          .finally(() => {
            setDisabledExcell(false)
          })
        }}
      />
      <Button
        disabled={disabledExcell}
        content="dia genesys"
        title="Baixar planilha"
        onClick={() => {
          setDisabledExcell(true)
          const day = excellDate.toString()
          const genesysorder = true
          const queryParam = genuser ? `?${new URLSearchParams({ day, genuser, genesysorder })}` : `?${new URLSearchParams({ day, genesysorder })}`
          const url = `${process.env.REACT_APP_API_URL}/rac/sheet${queryParam}`

          fetch(url, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
          })
          .then(response => response.json())
          .then(racs => {
            genExcelDownload({
              racs,
              driverList,
              groupList,
              excellDate
            }, 'genesys')
          })
          .finally(() => {
            setDisabledExcell(false)
          })
        }}
      />
      <Button
        disabled={disabledExcell}
        content="dia gen acc"
        title="Baixar planilha"
        onClick={() => {
          setDisabledExcell(true)
          const day = excellDate.toString()
          const genesysorder = true
          const queryParam = genuser ? `?${new URLSearchParams({ day, genuser, genesysorder })}` : `?${new URLSearchParams({ day, genesysorder })}`
          const url = `${process.env.REACT_APP_API_URL}/rac/sheet${queryParam}`

          fetch(url, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
          })
          .then(response => response.json())
          .then(racs => {
            genExcelDownloadAcc({
              racs,
              driverList,
              groupList,
              excellDate
            }, 'genesys')
          })
          .finally(() => {
            setDisabledExcell(false)
          })
        }}
      />
    </Flex>
    <Divider />
    <Flex hAlign="center" style={{ paddingTop: '1rem' }}>
      <Input placeholder="pipefy" value={pipefyInput} onChange={(ev, data) => setPipefyInput(data.value)}/>
        <Button icon={<AddIcon />} iconOnly title="+" onClick={() => {
          if (pipefyInput && !pipefys.includes(pipefyInput)) {
            const newPipefysList = [...pipefys, pipefyInput]
            setPipefys(newPipefysList)
          }
          setPipefyInput('')
        }}/>
        <Button content="por pipefys escolhidos" disabled={disabledExcell} onClick={() => {
          if (pipefys.length > 0) {
            setDisabledExcell(true)
            const url = `${process.env.REACT_APP_API_URL}/rac/sheet`
            const partname = new Date().toLocaleTimeString('pt-BR').replaceAll(':', '-')
            fetch(url, {
              method: 'POST',
              credentials: 'include',
              redirect: 'follow',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(pipefys)
            })
              .then(response => response.json())
              .then(racs => {
                genExcelDownload({
                  racs,
                  driverList,
                  groupList,
                  excellDate
                }, partname)
              })
              .finally(() => {
                setDisabledExcell(false)
              })
          }
        }}/>
    </Flex>
    <Flex hAlign="center" gap="gap.small" wrap style={{
      maxWidth: '800px',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: '1rem'
    }}>
    {
      pipefys.map((el, idx) => <Button key={`${idx}${el}`} content={el} onClick={() => setPipefys(pipes => pipes.filter((_el, idxIn) => idxIn !== idx))}/>)
    }
    </Flex>
  </>
}

  export default DownloadExcelButton