import React, { useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Flex, Form, FormDropdown, SplitButton } from "@fluentui/react-northstar";

const DriverPicker = ({ driverList, setDriverList, groupList, onMainClick }) => {
  const groupNamesOrdered = groupList?.map(el => el.group).sort() || []
  const [groupTip, setGroupTip] = useState()
  const [driverNamesOrdered, setDriverNamesOrdered] = useState([])
  const [driverName, setDriverName] = useState('')
  const [driver, setDriver] = useState()

  return <Flex hAlign="center">
  <Form style={{ width: '24rem' }}>
    <FormDropdown
      placeholder="Grupo"
      items={groupNamesOrdered}
      fluid={true}
      searchQuery={groupTip?.name ?? ''}
      onChange={(ev, data) => {
        if (data.value) {
          const groupname = data.value

          const selectedGroup = groupList.find(el => el.group === groupname)
          setGroupTip(selectedGroup)

          const driversname = driverList
            .filter(el => el.group === groupname)
            .map(el => el.name)
            .sort()

          setDriverNamesOrdered(driversname)
          setDriverName('')
        }
      }}
    />
    <FormDropdown
      placeholder="Motorista"
      items={driverNamesOrdered}
      search={true}
      fluid={true}
      value={driverName}
      searchQuery={driverName}
      onSearchQueryChange={(ev, data) => {
        setDriverName(data.searchQuery)
        if (data.searchQuery !== data.value) {
          setDriver()
        } else if (!driver) {
          const selectedDriver = driverList.find(el => el.group === groupTip.group && el.name === data.value)
          setDriver(selectedDriver)
        }
      }}
      onChange={(ev, data) => {
        setDriverName(data.searchQuery)
        const selectedDriver = driverList.find(el => el.group === groupTip?.group && el.name === data.value)
        setDriver(selectedDriver)
      }}
    />
    <SplitButton
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
      primary={true}
      disabled={!driver}
      button={{ content: 'Iniciar lançamento de benefício', 'aria-roledescription': 'splitbutton' }}
      toggleButton={{ 'aria-label': 'more options' }}
      menu={[
        {
          key: 'BuscarComprovantes',
          content: 'Buscar comprovantes no histórico',
          onClick: () => {

          }
        },
        {
          key: 'EditarCartao',
          content: 'Editar status cartão FLASH',
          onClick: () => {
            const url = `${process.env.REACT_APP_URL}/#/flashcard/${driver.cpf}`

            microsoftTeams.tasks.startTask({
              title: 'FLASH CARD',
              url
            }, (err, data) => {
              if (!err && data.cpf) {
                // const updatedDriverIdx = driverList.findIndex(el => el.cpf === data.cpf)
                // const updatedDriver = {
                //   ...driverList[updatedDriverIdx],
                //   flashcard: data.flashcard
                // }
                // const pre = driverList.slice(0, updatedDriverIdx)
                // const pos = driverList.slice(updatedDriverIdx + 1)
                // const newDriverList = [...pre, updatedDriver, ...pos]
                // setDriverList(newDriverList)
                setDriver()
                setDriverName('')
                setDriverNamesOrdered([])
                setGroupTip()
              }
            })
          }
        },
      ]}
      onMainButtonClick={() => {
        const driverSelected = driver
        const groupSelected = groupTip
        setDriverName('')
        setDriver()
        setGroupTip()
        onMainClick({
          driverSelected,
          groupSelected
        })

      }}
    />
  </Form>
</Flex>
}

export default DriverPicker
